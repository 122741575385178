import { AppScreen } from "../AppScreen";
import { AppEvent } from "./AppEvent";

export class OnlineCountChangeEvent extends AppEvent
{
    public static COUNT_CHANGED: string = "onlineCountChanged";

    constructor(type: string, public count: number)
    {
        super(type);
    }
}