import React, { Component } from "react";

export interface ButtonProps
{
    children?: React.ReactNode;
    link?: string;
    title: string;
    type: ButtonType;
    className?: string;
    onClick?: () => void;
}

export enum ButtonType 
{
    Link,
    Button
}

interface ButtonState 
{
}

export class Button extends Component<ButtonProps, ButtonState>
{
    constructor(props: ButtonProps)
    {
        super(props);

        this.state = {
        };
    }


    public render(): JSX.Element
    {
        return (
            <>
                {this.props.type === ButtonType.Link ?
                    <a className="btn" href={this.props.link} title={this.props.title} target="_blank">
                        <span className="title">{this.props.children}</span>
                    </a>
                    : null
                }

                {this.props.type === ButtonType.Button ?
                    <a className={"btn " + this.props.className} onClick={this.props.onClick?.bind(this)} title={this.props.title}>
                        <span className="title">{this.props.children}</span>
                    </a>
                    : null
                }
            </>
        );
    }

    public componentWillUnmount(): void
    {
    }
}