import React, { Component } from "react";
import { App } from "../../App";
import { AppScreen } from "../../AppScreen";
import { Button, ButtonType } from "./components/Button";

export interface StreamersProps
{
}

interface StreamersState 
{
    screen: Screen;
}

enum Screen 
{
    StreamersList,
    StreamersListContinued
}

export class Streamers extends Component<StreamersProps, StreamersState>
{
    constructor(props: StreamersProps)
    {
        super(props);

        this.state = {
            screen: Screen.StreamersList
        };
    }

    private onChangeScreenClicked(appScreen: AppScreen): void 
    {
        App.I.changeScreen(appScreen);
    }

    private onMoreClicked(): void 
    {

    }

    private onBackToStreamerListClicked(): void 
    {
        this.setState({
            screen: Screen.StreamersList
        });
    }

    public render(): JSX.Element
    {
        return (
            <nav>
                {(() => 
                {
                    switch (this.state.screen)
                    {
                        case Screen.StreamersList:
                            return (
                                <>
                                    <Button type={ButtonType.Link} link="https://www.twitch.tv/lgnd_99" title="Lgnd">Lgnd</Button>
                                    <Button type={ButtonType.Link} link="https://www.twitch.tv/lloyditfc96" title="Lloyd">Lloyd</Button>
                                    <Button type={ButtonType.Link} link="https://www.twitch.tv/snarksn" title="Snark">Snark</Button>
                                    <Button type={ButtonType.Link} link="https://www.twitch.tv/vltality" title="Edd">Edd</Button>
                                    <Button type={ButtonType.Link} link="https://www.twitch.tv/fortuneschaos" title="FC">FC Twitch</Button>
                                    <Button type={ButtonType.Link} link="https://www.youtube.com/c/BryanVahey" title="Bryan">Bryan YouTube</Button>
                                    <Button type={ButtonType.Link} link="https://www.twitch.tv/andygaminggreatjob" title="Andy">Andy Twitch</Button>
                                    <Button type={ButtonType.Link} link="https://www.twitch.tv/leyw7n" title="Qien Twitch">Qien Twitch</Button>
                                    <Button type={ButtonType.Button} onClick={this.onChangeScreenClicked.bind(this, AppScreen.IversBlitz)} title="Back">Back</Button>
                                </>
                            );

                        case Screen.StreamersListContinued:
                            return (
                                <>
                                </>
                            );
                    }

                })()}
            </nav>
        );
    }

    public componentWillUnmount(): void
    {
    }
}