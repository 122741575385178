import React from "react";
import { createRoot, Root } from "react-dom/client";
import { AppScreen } from "./AppScreen";
import { EventDispatcher } from "./events/AppEvent";
import { AppScreenChangedEvent } from "./events/AppScreenChangedEvent";
import { OnlineCountChangeEvent } from "./events/OnlineCountChangeEvent";
import { MainUI } from "./ui/MainUI";

export class App extends EventDispatcher
{
    private static instance: App;

    constructor()
    {
        super();

        this.render();

        App.instance = this;
    }

    public async onUIReady(): Promise<void>
    {
        await this.initializeApp();
    }

    private async initializeApp(): Promise<void>
    {
        // Every minute
        this.fetchOnlineCount();
        setInterval(() => this.fetchOnlineCount(), 60 * 1000);
    }

    public changeScreen(appScreen: AppScreen): void
    {
        this.dispatchEvent(new AppScreenChangedEvent(AppScreenChangedEvent.SCREEN_CHANGED, appScreen));
    }

    private async fetchOnlineCount(): Promise<void> 
    {
        let url = "https://api.cncnet.org/status";
        let response = await fetch(url, {
            method: "GET",
        });

        let responseText = await response.json();
        let count: number = responseText.cncnet5_yr;
        this.dispatchEvent(new OnlineCountChangeEvent(OnlineCountChangeEvent.COUNT_CHANGED, count));
    }

    private render(): void
    {
        const root: Root = createRoot(document.getElementById("root") || document.createElement("div"));
        root.render(React.createElement(MainUI, { onUIReadyCB: this.onUIReady.bind(this) }, null));
    }


    public static get I(): App { return App.instance; }
}
