import React, { Component } from "react";
import { App } from "../../App";
import { AppScreen } from "../../AppScreen";
import { Button, ButtonType } from "./components/Button";

export interface IversBlitzProps
{
}

interface IversBlitzState 
{
}

export class IversBlitz extends Component<IversBlitzProps, IversBlitzState>
{
    constructor(props: IversBlitzProps)
    {
        super(props);

        this.state = {
        };
    }

    private onChangeScreenClicked(appScreen: AppScreen): void 
    {
        App.I.changeScreen(appScreen);
    }

    public render(): JSX.Element
    {
        return (
            <nav>
                <Button type={ButtonType.Link} link="https://discord.com/invite/TNq9zEVxFt" title="Discord">Discord</Button>
                <Button type={ButtonType.Link} link="https://linktr.ee/ra2donate" title="Donate">Donate</Button>
                <Button type={ButtonType.Link} link="https://www.twitch.tv/ra2worldseries" title="Twitch">Twitch</Button>
                <Button type={ButtonType.Link} link="https://www.youtube.com/channel/UCjbqe2bpLyTjZAhQ7_oYhOg?sub_confirmation=1" title="YouTube">YouTube</Button>
                <Button type={ButtonType.Link} link="https://www.tiktok.com/@redalert2.com" title="Tiktok">Tiktok</Button>
                <Button type={ButtonType.Link} link="https://www.instagram.com/red.alert.2.pro.games/" title="Instagram">Instagram</Button>
                <Button type={ButtonType.Link} link="http://twitter.com/@RedAler95458110" title="Twitter">Twitter</Button>
                <Button type={ButtonType.Link} link="https://www.facebook.com/redalert2blitz" title="Facebook">Facebook</Button>
                <Button type={ButtonType.Button} onClick={this.onChangeScreenClicked.bind(this, AppScreen.MainMenu)} title="Back" className="back">Back</Button>
            </nav>
        );
    }

    public componentWillUnmount(): void
    {
    }
}