import { AppScreen } from "../AppScreen";
import { AppEvent } from "./AppEvent";

export class AppScreenChangedEvent extends AppEvent
{
    public static SCREEN_CHANGED: string = "screenChanged";

    constructor(type: string, public appScreen: AppScreen)
    {
        super(type);
    }
}