import React, { Component } from "react";
import { App } from "../App";
import { AppScreen } from "../AppScreen";
import { AppScreenChangedEvent } from "../events/AppScreenChangedEvent";
import { OnlineCountChangeEvent } from "../events/OnlineCountChangeEvent";
import { IversBlitz } from "./screens/IversBlitz";
import { MainMenu } from "./screens/MainMenu";
import { RA2WorldSeries } from "./screens/RA2WorldSeries";
import { RA2YR } from "./screens/RA2YR";
import { Streamers } from "./screens/Streamers";

export interface MainProps
{
    onUIReadyCB: () => void;
}

interface MainState 
{
    appScreen: AppScreen;
    online: number | null;
}

export class MainUI extends Component<MainProps, MainState>
{
    constructor(props: MainProps)
    {
        super(props);

        this.state = {
            appScreen: AppScreen.MainMenu,
            online: null
        };
    }

    public componentDidMount(): void
    {
        this.props.onUIReadyCB();

        App.I.addEventListener(AppScreenChangedEvent.SCREEN_CHANGED, this.onScreenChanged, this);
        App.I.addEventListener(OnlineCountChangeEvent.COUNT_CHANGED, this.onCountChanged, this);
    }

    private onScreenChanged({ appScreen }: AppScreenChangedEvent)
    {
        this.setState({
            appScreen
        });
    }

    private onCountChanged({ count }: OnlineCountChangeEvent): void
    {
        this.setState({
            online: count
        })
    }

    public render(): JSX.Element
    {
        return (
            <>
                <div className="main">
                    <div className="mobile">

                        <section className="mobile-header-background">
                            <div className="logo">
                                <img src="images/logo.png" alt="Red Alert 2 Logo" />
                                <div className="count">{this.state.online ? <>Players Online: {this.state.online}</> : null}</div>
                            </div>
                        </section>

                        <section className="mobile-menu">
                            {
                                (() =>
                                {
                                    switch (this.state.appScreen)
                                    {
                                        case AppScreen.MainMenu:
                                            return (
                                                <MainMenu />
                                            );

                                        case AppScreen.IversBlitz:
                                            return (
                                                <IversBlitz />
                                            );

                                        case AppScreen.RA2YR:
                                            return (
                                                <RA2YR />
                                            );

                                        case AppScreen.More:
                                            return (
                                                <RA2WorldSeries />
                                            );

                                        case AppScreen.Streamers:
                                            return (
                                                <Streamers />
                                            );
                                    }
                                })()
                            }
                        </section>
                    </div>

                    <div className="desktop">
                        <section className="menu-background">
                            <video src="videos/animation.webm" autoPlay muted controls={false} loop style={{ zIndex: -1 }} />

                            <div className="menu-header">
                                <div className="count">{this.state.online ? <>Players Online: {this.state.online}</> : null}</div>
                            </div>

                            {
                                (() =>
                                {
                                    switch (this.state.appScreen)
                                    {
                                        case AppScreen.MainMenu:
                                            return (
                                                <MainMenu />
                                            );

                                        case AppScreen.IversBlitz:
                                            return (
                                                <IversBlitz />
                                            );

                                        case AppScreen.RA2YR:
                                            return (
                                                <RA2YR />
                                            );

                                        case AppScreen.More:
                                            return (
                                                <RA2WorldSeries />
                                            );

                                        case AppScreen.Streamers:
                                            return (
                                                <Streamers />
                                            );
                                    }
                                })()
                            }
                        </section>
                    </div>

                    <footer>
                        <p>This page is not officially supported by, nor does it have any affiliation with EA Games or Command & Conquer.</p>
                    </footer>
                </div>

                <div className="carville"></div>
                <div className="tanya"></div>
            </>
        );
    }

    public componentWillUnmount(): void
    {
        App.I.removeEventListener(AppScreenChangedEvent.SCREEN_CHANGED, this.onScreenChanged);
    }
}