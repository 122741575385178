import React, { Component } from "react";
import { App } from "../../App";
import { AppScreen } from "../../AppScreen";
import { Button, ButtonType } from "./components/Button";

export interface RA2YRProps
{
}

interface RA2YRState 
{
}

export class RA2YR extends Component<RA2YRProps, RA2YRState>
{
    constructor(props: RA2YRProps)
    {
        super(props);

        this.state = {
        };
    }

    private onChangeScreenClicked(appScreen: AppScreen): void 
    {
        App.I.changeScreen(appScreen);
    }

    public render(): JSX.Element
    {
        return (
            <nav>
                <Button type={ButtonType.Link} link="https://discord.gg/dhyRBab4b7" title="Discord">Discord</Button>
                <Button type={ButtonType.Link} link="https://www.youtube.com/c/FortunesChaos" title="YouTube">YouTube</Button>
                <Button type={ButtonType.Link} link="https://cncnet.org/" title="CnCNet">CnCNet</Button>
                <Button type={ButtonType.Link} link="https://www.youtube.com/playlist?list=PLwlPpv3OXxX__Cex3sZEYsEnf3hUtay-Q" title="Making Maps">Making Maps</Button>
                <Button type={ButtonType.Button} onClick={this.onChangeScreenClicked.bind(this, AppScreen.MainMenu)} title="Back" className="back">Back</Button>
            </nav>
        );
    }

    public componentWillUnmount(): void
    {
    }
}