import React, { Component } from "react";
import { App } from "../../App";
import { AppScreen } from "../../AppScreen";
import { Button, ButtonType } from "./components/Button";

export interface RA2WorldSeriesProps
{
}

interface RA2WorldSeriesState 
{
}

export class RA2WorldSeries extends Component<RA2WorldSeriesProps, RA2WorldSeriesState>
{
    constructor(props: RA2WorldSeriesProps)
    {
        super(props);

        this.state = {
        };
    }

    private onChangeScreenClicked(appScreen: AppScreen): void 
    {
        App.I.changeScreen(appScreen);
    }

    public render(): JSX.Element
    {
        return (
            <nav>
                <Button type={ButtonType.Link} link="https://discord.gg/ZZvg3zEYaa" title="Pro Tournaments & For Fun Games">YR Discord</Button>
                <Button type={ButtonType.Link} link="https://cncnet.org/" title="CnCNet">CnCNet</Button>
                <Button type={ButtonType.Link} link="https://www.youtube.com/playlist?list=PLwlPpv3OXxX__Cex3sZEYsEnf3hUtay-Q" title="Making Maps">Making Maps</Button>
                <Button type={ButtonType.Link} link="https://www.youtube.com/c/FortunesChaos" title="Pro Tournaments & For Fun Games">FC YouTube</Button>
                <Button type={ButtonType.Link} link="https://www.twitch.tv/Doof88" title="Pro 1v1 Games & For Fun Games">Doof Twitch</Button>
                <Button type={ButtonType.Link} link="https://www.twitch.tv/mj_vst?sr=a" title="Pro 1v1 & Tournaments & For Fun Games">MJ Twitch</Button>
                <Button type={ButtonType.Link} link="https://www.twitch.tv/omituinen" title="One of the best players in the world!">Marko Twitch</Button>
                <Button type={ButtonType.Button} onClick={this.onChangeScreenClicked.bind(this, AppScreen.Streamers)} title="More">More</Button>

                <Button type={ButtonType.Button} onClick={this.onChangeScreenClicked.bind(this, AppScreen.MainMenu)} title="Back" className="back">Back</Button>
            </nav>
        );
    }

    public componentWillUnmount(): void
    {
    }
}