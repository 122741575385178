import React, { Component } from "react";
import { App } from "../../App";
import { AppScreen } from "../../AppScreen";
import { Button, ButtonType } from "./components/Button";

export interface MainMenuProps
{
}

interface MainMenuState 
{
}

export class MainMenu extends Component<MainMenuProps, MainMenuState>
{
    constructor(props: MainMenuProps)
    {
        super(props);

        this.state = {
        };
    }

    private onChangeScreenClicked(appScreen: AppScreen): void 
    {
        App.I.changeScreen(appScreen);
    }

    public render(): JSX.Element
    {
        return (
            <nav>
                <Button type={ButtonType.Link} link="https://youtu.be/n_xWvNxO55c" title="Download & Play">Download &amp; Play</Button>
                <Button type={ButtonType.Link} link="https://discord.gg/uKu82EuKJh" title="Tournament Signup">Tournament Signup</Button>
                <Button type={ButtonType.Link} link="https://discord.gg/uKu82EuKJh" title="Discord">Discord</Button>
                <Button type={ButtonType.Link} link="https://www.youtube.com/channel/UCjbqe2bpLyTjZAhQ7_oYhOg?sub_confirmation=1" title="YouTube">YouTube</Button>
                <Button type={ButtonType.Link} link="https://www.twitch.tv/ra2worldseries" title="Twitch">Twitch</Button>
                <Button type={ButtonType.Link} link="https://linktr.ee/ra2donate" title="Donate">Donate</Button>
                <Button type={ButtonType.Link} link="https://red-alert-2-world-series.myspreadshop.com/" title="Merchandise">Merchandise</Button>
                <Button type={ButtonType.Link} link="https://www.lulu.com/shop/ra2themooncom/red-alert-2-war-torn-lovers-unofficial-non-commercial-fanfiction/paperback/product-nkzdmy.html?page=1&pageSize=4" title="Comic Book">Comic Book</Button>

                <div className="blank"></div>

                <Button type={ButtonType.Button} onClick={this.onChangeScreenClicked.bind(this, AppScreen.More)} title="More">
                    More
                </Button>
            </nav>
        );
    }

    public componentWillUnmount(): void
    {
    }
}